import { CORE_CENTER } from '@/http/constants'
import { request } from '@/axios'
import { HotelInfo, OCRResult, SaveData, JiNingDictData } from './interfaces'
import { Region } from '@/http/index/interfaces'

enum Api {
    getHotelInfo = '/getHotelInfo',
    compress = '/wechat/compress',
    getRegionData = '/getCitiesList',
    getJiNingDict = '/getEpidemicDict',
    ocr = '/wechat/microProgram/ocr',
    checkStatus = '/checkInTeam',
    saveTeamCheckIn = '/saveTeamCheckIn',
    faceCompare = '/tengxun-cloud/faceCompare',
    saveSelfCheck = '/saveSelfCheck',
    getSelfCheck = '/getSelfCheck'
}

// 根据酒店code获取酒店信息
export const getHotelInfo = (hotelCode: string) => {
    return request<HotelInfo>({
        url: `${CORE_CENTER}${Api.getHotelInfo}?hotelCode=${hotelCode}`,
        method: 'GET'
    })
}

// 获取省市区数据
export const getRegionData = () => {
    return request<Region[]>({
        url: CORE_CENTER + Api.getRegionData,
        method: 'GET'
    })
}

// 获取济宁字典项
export const getJiNingDict = () => {
    return request<JiNingDictData>({
        url: CORE_CENTER + Api.getJiNingDict,
        method: 'GET'
    })
}

// OCR 身份证上传识别
export const ocr = (data: FormData) => {
    return request<{ code: number; message: string; result: OCRResult }>(
        {
            url: CORE_CENTER + Api.ocr,
            data
        },
        {
            isTransformRequestResult: true,
            errorMessageTextVisible: false
        }
    )
}

// 图片压缩
export const compress = (data: FormData) => {
    return request<string>({
        url: CORE_CENTER + Api.compress,
        data
    })
}

// 检查宾客是否已经登记和入住
export const checkStatus = (hotelId: number, idCardNo: string) => {
    return request<{ code: 0 | 1 | 2; message: string }>(
        {
            url: CORE_CENTER + Api.checkStatus,
            method: 'GET',
            params: { hotelId, idCardNo }
        },
        {
            isTransformRequestResult: true
        }
    )
}

export const saveTeamCheckIn = (data: SaveData) => {
    return request(
        {
            url: CORE_CENTER + Api.saveTeamCheckIn,
            data
        },
        {
            errorMessageTextVisible: true
        }
    )
}

export const faceCompare = (faceUrlA: string, faceUrlB: string) => {
    return request<{ code: number; result: number }>(
        {
            url: CORE_CENTER + Api.faceCompare,
            data: { faceUrlA, faceUrlB }
        },
        {
            isTransformRequestResult: true,
            errorMessageTextVisible: false
        }
    )
}

// 人脸核验三次未通过保存接口
export const saveSelfCheck = (idCardNo: string, hotelId: number | string) => {
    return request({
        url: CORE_CENTER + Api.saveSelfCheck,
        data: { idCardNo, hotelId }
    })
}

// 查询人员是否人脸封禁期间
export const getSelfCheck = (idCardNo: string, hotelId: number | string) => {
    return request<{ code: number; message: string; result: boolean }>(
        {
            url: CORE_CENTER + Api.getSelfCheck,
            method: 'GET',
            params: { idCardNo, hotelId }
        },
        {
            isTransformRequestResult: true,
            errorMessageTextVisible: false
        }
    )
}
