const toString = Object.prototype.toString

/**
 * @description: 判断值是否为某个类型
 * @param {unknown} val
 * @param {string} type
 * @return {*}
 */
export function is(val: unknown, type: string) {
    return toString.call(val) === `[object ${type}]`
}

/**
 * @description: 是否为函数
 * @param {unknown} val
 * @return {*}
 */
export function isFunction<T extends Function>(val: unknown): val is T {
    return is(val, 'Function')
}

/**
 * @description: 是否已定义
 * @param {*} T
 * @return {*}
 */
export const isDef = <T = unknown>(val?: T): val is T => {
    return typeof val !== 'undefined'
}

/**
 * @description: 是否未定义
 * @param {*} T
 * @return {*}
 */
export const isUnDef = <T = unknown>(val?: T): val is T => {
    return !isDef(val)
}

/**
 * @description: 是否为对象
 * @param {any} val
 * @param {*} any
 * @return {*}
 */
export const isObject = (val: any): val is Record<string, any> => {
    return val !== null && is(val, 'Object')
}

/**
 * @description: 是否为日期
 * @param {unknown} val
 * @return {*}
 */
export function isDate(val: unknown): val is Date {
    return is(val, 'Date')
}

/**
 * @description: 是否为数值
 * @param {unknown} val
 * @return {*}
 */
export function isNumber(val: unknown): val is number {
    return is(val, 'Number')
}

/**
 * @description:  是否为AsyncFunction
 */
export function isAsyncFunction<T = any>(val: unknown): val is Promise<T> {
    return is(val, 'AsyncFunction')
}
/**
 * @description: 是否为promise
 * @param {unknown} val
 * @return {*}
 */
export function isPromise<T = any>(val: unknown): val is Promise<T> {
    return is(val, 'Promise') && isObject(val) && isFunction(val.then) && isFunction(val.catch)
}

/**
 * @description: 是否为字符串
 * @param {unknown} val
 * @return {*}
 */
export function isString(val: unknown): val is string {
    return is(val, 'String')
}

/**
 * @description: 是否为boolean类型
 * @param {unknown} val
 * @return {*}
 */
export function isBoolean(val: unknown): val is boolean {
    return is(val, 'Boolean')
}

/**
 * @description:  是否为数组
 */
export function isArray(val: any): val is Array<any> {
    return val && Array.isArray(val)
}

/**
 * @description: 是否为错误对象
 * @param {any} val
 * @param {*} any
 * @return {*}
 */
export const isErrorObject = (val: any): val is Record<any, any> => {
    return val !== null && is(val, 'Error')
}

/**
 * @description:  是否为JSON对象
 */
export function isJsonObj(obj: any) {
    const isJson = typeof obj === 'object' && Object.prototype.toString.call(obj).toLowerCase() === '[object object]' && !obj.length
    return isJson
}

const { VUE_APP_MODE } = process.env

//是否是本地环境
export const isLocal = VUE_APP_MODE === 'local'
//是否是开发环境
export const isDev = VUE_APP_MODE === 'dev'
//是否是生产环境
export const isProd = VUE_APP_MODE === 'prod'
// 是否测试环境
export const isSit = VUE_APP_MODE === 'sit'

export const isIOS = () => window.navigator.platform.indexOf('iPhone') !== -1

const u = window.navigator.userAgent

export const isAndroid = () => u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
