export enum PSBFirm {
    default = '',
    /**
     * 神盾
     */
    shenDun = 'shenDun',
    /**
     * 金盾
     */
    jinDun = 'jinDun',
    /**
     * 大江
     */
    daJiang = 'daJiang',
    /**
     * 达因
     */
    daYin = 'daYin',
    /**
     * 亿恩
     */
    yiEn = 'yiEn',
    /**
     * 广西南宁
     *
     * */
    guangXiNanNing = 'guangXiNanNing',
    /**
     * 其他
     */
    other = 'other'
}

export interface HotelInfo {
    id: number
    hotelCode: string
    hotelName: string
    province: string
    city: string
    district: number
    address: string
    industry: string
    brandName: string
    licenseName: string
    licenseCode: string
    registeredAddress: string
    psbType: PSBFirm
    registrationFlag: 'yes' | 'no'
    nations?: Nation[]
}

export interface Nation {
    code: string
    name: string
}

export interface OCRResult {
    addr: string
    endDate: string
    errcode: string
    errmsg: string
    gender: string
    id: string
    name: string
    nationality: string
    startDate: string
    imgUrl: string
    type: 'Front' | 'Back'
}

export interface SaveData {
    guestInfoRq: GuestInfoRq
    hotelId: number
    psbFirm: PSBFirm
}

export interface GuestInfoRq {
    name: string
    birthday: string
    idCardNo: string
    sex: 1 | 2 // 1 男
    nation: string // 民族
    phone: string
    address: string
    photo: string
    cameraImg: string
    score: string
    consistentFlag: 1 | 2 // 1 一致
    infoType: '11'
    gIsMinor: '0' // 是否是未成年人 (0否 1是)
    strLXFS: string
    verifiedSign: '0'
    countryKbn: 1
    inputMethodName: ''
    inputMethodCode: ''
    strJCGWYQ: 0 | 1 // 是否国外回来 1是0否
    strBGS: number // 有无核酸检测报告 1是0否
    epidemicFlag: 0 | 1 // 14日是否填写 0-否 1-是
    strWLZGFXDQ?: string[]
    strLXBDBR?: number // 联系本人 1已经联系 2联系不上
    xzgdqk?: number[]
    psbConsistentFlag?: number
    guardianType?: string
    guardianRelationshipOther?: string
    guardianKnowFlag?: string
    guardianRoomNo?: string
    guardianAddr?: string
    guardianDyType?: number
    healthCode?: 1 | 2 | 3 // 健康码 1-绿 2-黄 3-红
    strBGSSJ?: string
    strBGSJCJG?: string
    strSFZGFXLJS?: number
    strCome?: string
    strDDFS?: number
    strDDFSXQ?: string
    strTJSS?: string
    strDDSS?: string
    strLDFS?: string
    strLDFSXQ?: string
    strDQZK?: string
    strDQTW?: string
    strSFGLJN?: number
    strSFJC?: number
    strTXRY?: string
    checkIdDaYin?: string
    visaValidityDate?: string
}

export interface WayCity {
    label: string
    options: { label: string; value: string }[]
}

export interface JiNingDict {
    code: string
    default: boolean
    name: string
}

export interface JiNingDictData {
    operationArriveType?: JiNingDict[]
    operationComeType?: JiNingDict[]
    operationHealthState?: JiNingDict[]
}
