export enum Status {
    wait,
    pending,
    success,
    fail
}

export enum ErrorCode {
    takePhoto = 1000
}
