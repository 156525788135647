import dayjs from 'dayjs'

export type Lazy<T> = (retryCount: number) => Promise<T>

export const importWithRetry = <T = any>(lazy: Lazy<T>, retry = 3, delay?: number) => {
    return new Promise<T>((resolve, reject) => {
        let retryCount = 0
        const load = () => {
            lazy(retryCount).then(
                (res) => {
                    resolve(res)
                },
                (err) => {
                    retryCount++
                    if (retryCount > retry) {
                        reject(err)
                        return
                    }
                    console.log(`重试第${retryCount}次`)
                    if (delay && delay > 0) {
                        setTimeout(load, delay)
                    } else {
                        load()
                    }
                }
            )
        }
        load()
    })
}

export const wait = (delay = 1000) => new Promise((resolve) => setTimeout(resolve, delay))

export const getBirthdayById = (id: string) => {
    if (id.length === 18) {
        return `${id.substring(6, 10)}-${id.substring(10, 12)}-${id.substring(12, 14)}`
    }
    if (id.length === 15) {
        const year = id.substring(6, 8)
        const yearPrefix = parseInt(year) < 10 ? 20 : 19
        return `${yearPrefix}${year}-${id.substring(8, 10)}-${id.substring(10, 12)}`
    }
    return ''
}

// 身份证验证
export const checkIdCard = (value: string) => {
    const vCity = {
        11: '北京',
        12: '天津',
        13: '河北',
        14: '山西',
        15: '内蒙古',
        21: '辽宁',
        22: '吉林',
        23: '黑龙江',
        31: '上海',
        32: '江苏',
        33: '浙江',
        34: '安徽',
        35: '福建',
        36: '江西',
        37: '山东',
        41: '河南',
        42: '湖北',
        43: '湖南',
        44: '广东',
        45: '广西',
        46: '海南',
        50: '重庆',
        51: '四川',
        52: '贵州',
        53: '云南',
        54: '西藏',
        61: '陕西',
        62: '甘肃',
        63: '青海',
        64: '宁夏',
        65: '新疆',
        71: '台湾',
        81: '香港',
        82: '澳门',
        83: '台湾',
        91: '国外'
    }

    // 判断是否为空
    const isEmpty = function (card: string) {
        if (/^\s*$/.test(card)) {
            return true
        }
    }
    // 检查号码是否符合规范，包括长度，类型
    const isCardNo = function (card: string) {
        if (isEmpty(card)) {
            return true
        }
        // 这个代码表示身份证可以为空
        // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
        const reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/
        if (!reg.test(card)) {
            return false
        }

        return true
    }

    // 取身份证前两位,校验省份
    const checkProvince = function (card: string) {
        if (isEmpty(card)) {
            return true
        }
        const province = card.substr(0, 2)
        // @ts-ignore
        if (vCity[province] === undefined) {
            return false
        }
        return true
    }

    // 检查生日是否正确
    const checkBirthday = function (card: string) {
        if (isEmpty(card)) {
            return true
        }
        const len = card.length
        // 身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
        if (len === 15) {
            const reFifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/
            const arrData = card.match(reFifteen)
            // @ts-ignore
            const year = arrData[2]
            // @ts-ignore
            const month = arrData[3]
            // @ts-ignore
            const day = arrData[4]
            const birthday = new Date('19' + year + '/' + month + '/' + day)
            return verifyBirthday('19' + year, month, day, birthday)
        }
        // 身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
        if (len === 18) {
            const reEighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/
            const arrData = card.match(reEighteen)
            // @ts-ignore
            const year = arrData[2]
            // @ts-ignore
            const month = arrData[3]
            // @ts-ignore
            const day = arrData[4]
            const birthday = new Date(year + '/' + month + '/' + day)
            return verifyBirthday(year, month, day, birthday)
        }
        return false
    }

    // 校验日期
    const verifyBirthday = function (year: string, month: string, day: string, birthday: Date) {
        const now = new Date()
        const nowYear = now.getFullYear()
        // 年月日是否合理
        if (birthday.getFullYear() === +year && birthday.getMonth() + 1 === +month && birthday.getDate() === +day) {
            // 判断年份的范围（1岁到100岁之间)
            // @ts-ignore
            const time = nowYear - year
            if (time >= 1 && time <= 100) {
                return true
            }
            return false
        }
        return false
    }

    // 校验位的检测
    const checkParity = function (card: string) {
        if (isEmpty(card)) {
            return true
        }
        // 15位转18位
        // @ts-ignore
        card = changeFivteenToEighteen(card)

        const len = card.length
        if (len === 18) {
            const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
            const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
            let cardTemp = 0
            for (let i = 0; i < 17; i++) {
                // @ts-ignore
                cardTemp += card.substr(i, 1) * arrInt[i]
            }
            const valnum = arrCh[cardTemp % 11]
            if (valnum === card.substr(17, 1)) {
                return true
            }
            return false
        }
        return false
    }

    // 15位转18位身份证号
    const changeFivteenToEighteen = function (card: string) {
        if (isEmpty(card)) {
            return true
        }
        if (card.length === 15) {
            const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
            const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
            let cardTemp = 0
            card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6)
            for (let i = 0; i < 17; i++) {
                // @ts-ignore
                cardTemp += card.substr(i, 1) * arrInt[i]
            }
            card += arrCh[cardTemp % 11]
            return card
        }
        return card
    }

    // checkCard = function () {
    const card = value.toUpperCase()
    // 校验长度，类型
    if (!isCardNo(card)) {
        // alert('您输入的身份证号码不正确，请重新输入');
        // document.getElementById('card_no').focus;
        return false
    }
    // 检查省份
    if (!checkProvince(card)) {
        return false
    }
    // 校验生日
    if (!checkBirthday(card)) {
        return false
    }
    // 检验位的检测
    if (!checkParity(card)) {
        return false
    }

    return true
}
