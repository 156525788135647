import axios, { AxiosRequestConfig } from 'axios'
import { Toast } from 'vant'
import clonedeep from 'lodash.clonedeep'
import { isLocal, isObject } from '@/helper/is'

export interface Options {
    baseUrl?: string
    //  是否处理请求结果
    isTransformRequestResult?: boolean
    // 成功的文本信息
    successMessageText?: string
    // 错误的文本信息
    errorMessageText?: string
    // 是否显示错误的文本信息
    errorMessageTextVisible?: boolean
    mockUrl?: string
    amountConversionList?: string[]
}

interface ResponseData<T> {
    code: number
    message: string
    result: T
}

interface ErrorResponseData {
    code: string
    errorLevel: string
    message: string
    status: number
}

const { VUE_APP_BASEURL } = process.env

export const InvalidTokenCodes = [4002, 4003]

const showToast = (title?: string, type?: 'text' | 'loading' | 'success' | 'fail' | 'html') => {
    if (!title) return
    Toast({
        message: title,
        type,
        duration: 3000
    })
}

const amountConversion = (data: any, amountConversionList: string[], isSend: boolean, arrayKey?: string): any => {
    if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            if ((data[i] && typeof data[i] === 'string') || typeof data[i] === 'number') {
                if (arrayKey) {
                    if (amountConversionList.includes(arrayKey)) {
                        if (isSend) {
                            data[i] = data[i] * 100
                        } else {
                            data[i] = data[i] / 100
                        }
                    }
                } else {
                    if (isSend) {
                        data[i] = data[i] * 100
                    } else {
                        data[i] = data[i] / 100
                    }
                }
            } else {
                amountConversion(data[i], amountConversionList, isSend)
            }
        }
    } else {
        for (const prop in data) {
            if (amountConversionList.includes(prop)) {
                if ((data[prop] && typeof data[prop] === 'string') || typeof data[prop] === 'number') {
                    if (isSend) {
                        data[prop] = data[prop] * 100
                    } else {
                        data[prop] = data[prop] / 100
                    }
                }
            }
            const isArray = Array.isArray(data[prop])
            if (data[prop] && (typeof data[prop] === 'object' || isArray)) {
                amountConversion(data[prop], amountConversionList, isSend, isArray ? prop : undefined)
            }
        }
    }
}

export const request = <T>(requestOptions: AxiosRequestConfig, options?: Options): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        const { url, method, params, data } = requestOptions
        const { baseUrl, isTransformRequestResult, successMessageText, errorMessageText, errorMessageTextVisible = true, mockUrl = '', amountConversionList = [] } = options ?? Object.create(null)
        const _url = (baseUrl || isLocal ? '/api' : VUE_APP_BASEURL!) + url
        if (amountConversionList.length > 0) {
            if (params && typeof params === 'object') {
                const cloneParams = clonedeep(params)
                amountConversion(cloneParams, amountConversionList, true)
                requestOptions.params = cloneParams
            }
            if (data && typeof data === 'object') {
                const cloneData = clonedeep(data)
                amountConversion(cloneData, amountConversionList, true)
                requestOptions.data = cloneData
            }
        }
        axios
            .request({
                ...requestOptions,
                url: mockUrl ? mockUrl : _url,
                method: method || 'POST'
            })
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data as ResponseData<T>
                    if (isTransformRequestResult) {
                        if (InvalidTokenCodes.includes(data.code)) {
                            showToast(data.message || '请重新登录', 'text')
                        } else {
                            if (amountConversionList.length > 0) {
                                amountConversion(data?.result || Object.create(null), amountConversionList, false)
                            }
                        }
                        resolve(data as any)
                    } else {
                        if (data.code === 0) {
                            if (amountConversionList.length > 0) {
                                amountConversion(data?.result || Object.create(null), amountConversionList, false)
                            }
                            showToast(successMessageText, 'success')
                            resolve(data.result)
                        } else {
                            showToast(errorMessageText || data.message || '系统异常', 'text')
                            reject(data.result)
                        }
                    }
                } else {
                    if (errorMessageTextVisible) {
                        if (isObject(res) && isObject(res.data)) {
                            const data = res.data as ErrorResponseData
                            showToast(data.message || errorMessageText || '系统异常', 'text')
                        } else {
                            showToast(errorMessageText || '系统异常', 'text')
                        }
                    }
                    reject(res)
                }
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    reject(err)
                    return
                }
                if (err && err.isAxiosError) {
                    const data = err.response?.data as ErrorResponseData
                    if (errorMessageTextVisible) {
                        showToast(errorMessageText || data?.message || '系统异常', 'text')
                    }
                    reject(data || err)
                    return
                }
                if (errorMessageTextVisible) {
                    showToast(errorMessageText || '系统异常', 'text')
                }
                reject(err)
            })
    })
}
