






import Vue from 'vue'
import { Column, Region } from '@/http/index/interfaces'

export default Vue.extend({
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        cities: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            columns: [] as { values: Column[] }[]
        }
    },
    created() {
        const { generateData } = this
        const cities = this.cities as Region[]
        this.columns[0] = {
            values: cities.map(generateData)
        }

        if (cities[0]?.childCities) {
            this.columns[1] = {
                values: cities[0].childCities.map(generateData)
            }

            if (cities[0].childCities[0].childCities?.length) {
                this.columns[2] = {
                    values: cities[0].childCities[0].childCities?.map(generateData)
                }
            }
        }
    },
    methods: {
        generateData(data: Region) {
            return {
                id: data.id,
                text: data.name,
                riskLevel: data.riskLevel
            }
        },
        onChange(vm: any, value: Column[], index: number) {
            const { generateData } = this
            const cities = (this.cities as Region[]).find((c) => c.id === value[0].id)
            if (index === 0) {
                if (cities && cities.childCities) {
                    vm.setColumnValues(
                        1,
                        cities.childCities.map(generateData)
                    )
                }
            }
            if (cities && index < 2) {
                let districts: Region | undefined = cities.childCities?.find((c) => c.id === value[1]?.id)
                if (!districts) districts = cities.childCities?.[0] ?? Object.create(null)
                vm.setColumnValues(
                    2,
                    districts!.childCities?.map(generateData) ?? []
                )
            }
        },
        onConfirm(data: Column[]) {
            this.$emit(
                'done',
                data.filter((c) => Boolean(c))
            )
            this.close()
        },
        close() {
            this.$emit('change', false)
        }
    }
})
