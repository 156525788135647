import { Dialog } from 'vant'

const getVideoStream = (stream: MediaStream, video: HTMLVideoElement, onPlay: (res: boolean) => void) => {
    video.setAttribute('autoplay', '')
    video.setAttribute('muted', '')
    video.setAttribute('webkit-playsinline', '')
    video.setAttribute('playsinline', '')
    // video.setAttribute('x-webkit-airplay', 'true')
    // video.setAttribute('airplay', 'true')
    try {
        video.srcObject = stream
    } catch (err) {
        // @ts-ignore
        if (video.mozSrcObject !== undefined) {
            // @ts-ignore
            video.mozSrcObject = buffer
        } else {
            video.src = window.URL && window.URL.createObjectURL(stream)
        }
        console.error(err)
        throw err
    }
    video.play().then(
        () => {
            onPlay(true)
        },
        (err) => {
            onPlay(false)
            throw err
        }
    )
}

const getFail = (error: any) => {
    const message = error.message || ''
    const response: Record<string, string> = {
        'permission denied': '浏览器禁止本页面使用摄像头，请开启相关的权限',
        'permission dismissed': '浏览器禁止本页面使用摄像头，请开启相关的权限',
        // 'could not start video source': '',
        'requested device not found': '未检测到摄像头'
    }
    Dialog.alert({
        message: response[message.toLowerCase()] || '浏览器禁止本页面使用摄像头，请开启相关的权限',
        confirmButtonText: '知道了',
        confirmButtonColor: '#4AC3BB'
    })
}

let mediaStream: MediaStream | null

export const stopAndClearStream = () => {
    if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
            track.stop()
        })
        mediaStream = null
    }
}

export const invokeCamera = (video: HTMLVideoElement, onPlay: (res: boolean) => void) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
            .getUserMedia({
                audio: false,
                video: { facingMode: 'user', width: 243, height: 243 }
            })
            .then((stream) => {
                mediaStream = stream
                // mediaStream.getTracks()
                getVideoStream(stream, video, onPlay)
            }, getFail)
        // @ts-ignore
    } else if (navigator.webkitGetUserMedia) {
        // @ts-ignore
        navigator.webkitGetUserMedia(
            { video: { facingMode: 'user', width: 243, height: 243 } },
            // @ts-ignore
            (stream) => {
                mediaStream = stream
                // mediaStream.getTracks()
                getVideoStream(stream, video, onPlay)
            },
            getFail
        )
    } else if (navigator.getUserMedia) {
        navigator.getUserMedia(
            { video: { facingMode: 'user', width: 243, height: 243 } },
            (stream) => {
                // mediaStream.getTracks()
                mediaStream = stream
                getVideoStream(stream, video, onPlay)
            },
            getFail
        )
    } else {
        Dialog.alert({
            message: '不支持摄像头调用!',
            confirmButtonText: '知道了',
            confirmButtonColor: '#4AC3BB'
        })
    }
}
