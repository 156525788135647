import { request } from '@/axios'
import { CORE_CENTER, CONFIGCENTER } from '@/http/constants'
import { MoreData, Region } from '@/http/index/interfaces'

enum Api {
    getServerTime = '/getServerTime',
    getCityAndMoreInfoData = '/getCityAndMoreInfoData',
    getHotelPsbFirm = '/no-token/getHotelPsbFirm',
    compress = '/wechat/compress',
    faceCompare = '/tengxun-cloud/faceCompare'
}

// 获取服务器时间
export const getServerTime = () => {
    return request<number>({
        url: CORE_CENTER + Api.getServerTime,
        method: 'GET'
    })
}

// 获取14日城市列表和更多情况
export const getCityAndMoreInfoData = (hotelId: number) => {
    return request<{ citySourceRps: Region[]; moreInfoRps: MoreData[] }>({
        url: CORE_CENTER + Api.getCityAndMoreInfoData,
        method: 'GET',
        params: { hotelId }
    })
}

export const getWxConfigData = () => {
    return request<any>({
        url: '/weixingateway/official-account/getSignature',
        method: 'POST',
        data: {
            url: window.location.href
        }
    })
}

export const getHotelPsbFirm = (hotelId: number) => {
    return request<string>({
        url: CONFIGCENTER + Api.getHotelPsbFirm,
        method: 'GET',
        params: {
            hotelId
        }
    })
}

// 图片压缩
export const compress = (data: FormData) => {
    return request<string>({
        url: CORE_CENTER + Api.compress,
        data
    })
}

export const faceCompare = (faceUrlA: string, faceUrlB: string) => {
    return request<{ code: number; result: number }>(
        {
            url: CORE_CENTER + Api.faceCompare,
            data: { faceUrlA, faceUrlB }
        },
        {
            isTransformRequestResult: true,
            errorMessageTextVisible: false
        }
    )
}
